import React, { Component } from "react";

export default class FLFooter extends Component {

  render() {
    return (
        <footer role="contentinfo" class="ui-section-footer">
        <div class="ui-layout-container">
          <div class="ui-section-footer__layout ui-layout-flex">
            <p class="ui-section-footer--copyright ui-text-note"><small>&copy; 0000 Uisual</small></p>
            <a href="#" role="link" aria-label="#" class="ui-text-note"><small>Features</small></a>
            <a href="#" role="link" aria-label="#" class="ui-text-note"><small>Help</small></a>
            <a href="#" role="link" aria-label="#" class="ui-text-note"><small>Terms</small></a>
          </div>
        </div>
      </footer>
    );
  }
}
