import React from 'react';
import Navbar from "../Components/Navbar";
import SectionHi from "../Components/SectionHi";
import SectionParagraph from "../Components/SectionParagraph";
import SectionContact from "../Components/SectionContact";

function Home() {
    const myExperienceArray = [
        { content: 'Currently Engineering Manager at Facebook, supporting Facebook Watch growth and new experiences efforts across all platforms.',url: 'https://www.facebook.com/watch'},
        { content: 'Tech lead at Yahoo! Mobile Search Team. Specifically, led the android development of the Yahoo Search App and SDK.',url: 'https://www.verizonmedia.com/partners/search-sdk' },
        { content: 'Software engineer at VMware, working on storage and platform for VMware cloud solutions.',url: 'https://www.vmware.com/' },
        { content: 'Co-Founder of Reclutec, startup to hire the best tech talent in LATAM',url: 'https://www.crunchbase.com/organization/reclutec' },
        { content: 'Co-Founder of DSB Mobile, Mobile solutions factory',url: 'https://www.dsbmobile.com/' },
        ];
    
      const myInitiativesArray = [
        { content: 'Co-founder of PeruSV (non-profit). We are a group of Peruvian tech professionals residing and working in Silicon Valley who are committed to have impact in Peru. As part of our initiatives we organize Meetups, Live Panels, Immersion Programs to Silicon Valley and the TECHSUYO conference',url: 'https://www.perusv.org'},
        { content: 'TECHSUYO is the conference that brings together the Peruvian professional community in the areas of science, technology and innovation.',url: 'https://www.techsuyo.org'},
        { content: 'Advisor - Silabuz.com. Silabuz is a coding school that focuses on offering programming courses online for kids. It connects Spanish-speaking Latin American students with professionals and online courses to learn or reinforce their skills and receive an education that is required for today’s digital world.',url: 'https://www.silabuz.com'},
        { content: 'Competitive coding coach. Coached the first peruvian team to qualify to the ICPC World Finals. ', url: 'https://youtu.be/2MqbcxZpkDI'},
        { content: 'Public Appearances. I like sharing my story and experience to motivate the next generation of computer scientists and software engineers.', url: 'https://youtu.be/rRyGghxDoK0'},
      ];
    
      const myContactArray = [
        { content: 'If this is related to mentorship/advising request, please fill this form and I will review the request.', button: 'Request Mentorship/Advise', url: 'https://forms.gle/ineNkGQAGyppncXu6'},
        { content: 'If this is related to public engagement: conference, interview, panel, writing, etc. Please send me a Linkedin Message.', stress:'I only talk about matters that are related to my personal experience, career or initiatives. I am not an offical public representative of my current company.', button: 'Contact Victor', url: 'https://www.linkedin.com/in/vlaguna/'},
      ];
  return (
    <div className="App">
      <Navbar />
      <SectionHi id="sectionHi"/>
      <SectionParagraph
        id="sectionExperience"
          title="Experience"
      content = {myExperienceArray}/>
      <SectionParagraph
        id="sectionInitiatives"
        title="Initiatives"
        content = {myInitiativesArray}/>
      <SectionContact
        id="sectionContact"
        title="Contact me"
        content = {myContactArray}/>
    </div>
  );
}

export default Home;