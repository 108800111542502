import React from 'react';
import './App.css';
import Home from './Screens/Home';
import Fastlane from './Screens/Fastlane'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/fastlane" element={<Fastlane/>}/>
      </Routes>
    </Router>
  );
}

export default App;
