import React from "react";

export default function SectionParagraph({ title, content, id }) {
  var items = content.map(item => (
        <div>
          <p>{item.content}</p>
          <a target="_blank" rel="noopener noreferrer" href={item.url}>{item.url}</a>
          <hr/>
        </div>
    ));
  return (
    <div className="section">
      <div className="section-content" id={id}>
        <h1>{title}</h1>
        {items}
      </div>
    </div>
  );
}
