import React from "react";

export default function SectionHi({ content, id }) {
  return (
    <div className="section">
      <div className="section-content" id={id}>
        <header className="App-header">
          <div class='sk-asset title'>
            <span class="span1">Hello! I’m Victor Laguna</span></div>
          <div class='sk-asset subtitle'>
            <span class="span1">Engineering Leader, Career Mentor and Startup Advisor.</span></div>
          <div class='sk-asset description'>
            <span class="span1">Inclusion advocate and proud LatinX 🇵🇪🇺🇸. Co-founder of <a target="_blank" href="https://www.perusv.org/" rel="noopener noreferrer">perusv.org</a> and co-organizer of <a target="_blank" href="https://www.techsuyo.org/" rel="noopener noreferrer">techsuyo.org</a>. </span></div>
          <div class='sk-asset sk-Social-Media-Icons'>
              <div class='sk-asset sk-facebook clickable' onclick="window.open('https://www.facebook.com/elvitucho', '_blank');"></div>
              <div class='sk-asset sk-floating_Instagram_normal clickable' onclick="window.open('https://www.instagram.com/elvitucho/', '_blank');"></div>
              <div class='sk-asset sk-floating_LinkedIn_normal clickable' onclick="window.open('https://www.linkedin.com/in/vlaguna/', '_blank');"></div>
              <div class='sk-asset sk-floating_Twitter_normal clickable' onclick="window.open('https://twitter.com/elvitucho', '_blank');">
                <div class='sk-asset sk-Oval'></div>
                <div class='sk-asset sk-Twitterlogo'></div>
              </div>
            </div>
      </header>
      </div>
    </div>
  );
}
