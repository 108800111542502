import React, { useState } from "react";
import { Button, ButtonGroup } from 'reactstrap';
import SectionHi from "./SectionHi";

export default function SectionContact({ title, content, id }) {

  const openUrl = (url) => {
    window.open(url, "_blank");
  }

  var items = content.map(item => (
        <div>
          <p>
          {item.content} &nbsp; <b>{item.stress}</b>
          </p>
          <Button color="primary" onClick={() => openUrl(item.url)}>{item.button} </Button>
          <hr/>
        </div>
    ));
    return (
      <div className="section">
        <div className="section-content" id={id}>
          <h1>{title}</h1>
          {items}
        </div>
      </div>
    );
}
