// Fastlane.js

import React from 'react';
import "./fastlane.css";
import FLHeader from '../Components/Fastlane/FLHeader';
import FLFooter from '../Components/Fastlane/FLFooter';

function Fastlane() {
  return (
    <div>
      <FLHeader />
      <main role="main">
        <section class="ui-section-content">
          <div class="ui-layout-container">
            <div class="ui-layout-column-6 ui-layout-column-center ui-section-hero">
              <h1> Fastlane</h1>
              <p class="ui-text-intro"> Rapidly evaluate, prototype and validate business ideas in minutes.</p>
              <div class="ui-component-cta ui-layout-flex">
                <form action="#" class="ui-component-form ui-layout-grid ui-layout-column-4">
                  <input type="email" placeholder="Email" class="ui-component-input ui-component-input-medium" required />
                  <button type="submit" class="ui-component-button ui-component-button-medium ui-component-button-primary">Join Waitlist</button>
                </form>
                <p class="ui-text-note"><small>Alpha Release available soon. Join for early access.</small></p>
              </div>
            </div>
            <div class="ui-section-content--feature ui-layout-grid ui-layout-grid-3">
              <div>
                <img src="https://res.cloudinary.com/uisual/image/upload/assets/icons/avatar.svg" alt="#" class="ui-section-content--icon" />
                <h3>Evaluate</h3>
                <p>Get insights about the market and potential opportunities.</p>
                </div>
              <div>
                <img src="https://res.cloudinary.com/uisual/image/upload/assets/icons/design.svg" alt="#" class="ui-section-content--icon" />
                <h3>Prototype</h3>
                <p>Build a landing page prototype in a matter of minutes with just a description.</p>
              </div>
              <div>
                <img src="https://res.cloudinary.com/uisual/image/upload/assets/icons/chat.svg" alt="#" class="ui-section-content--icon" />
                <h3>Validate</h3>
                <p>Get your prototype in the hands of potential customers and get the insights.</p>
              </div>
            </div>

          </div>
        </section>
        <section class="ui-section-close">
        <div class="ui-layout-container">
          <div class="ui-layout-column-6 ui-layout-column-center">
            <h2>Ready to start?</h2>
            <p class="ui-text-intro">Validate. Launch. Succeed.</p>
            <div class="ui-component-cta ui-layout-flex">
              <form action="#" class="ui-component-form ui-layout-grid ui-layout-column-4">
                <input type="email" placeholder="Email" class="ui-component-input ui-component-input-medium" required/>
                <button type="submit" class="ui-component-button ui-component-button-medium ui-component-button-primary">Join Waitlist</button>
              </form>
              <p class="ui-text-note"><small>Alpha Release available soon. Join for early access</small></p>
            </div>
          </div>
        </div>
      </section>
      </main>
      <FLFooter/>
    </div>
  );
}

export default Fastlane;